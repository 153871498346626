<template>
  <div
    class="alert-error absolute left-4 top-0 z-table-info flex gap-4 overflow-hidden rounded-b-lg"
    :class="open ? 'right-4' : ''"
  >
    <ChevronDownIcon
      :class="
        open ? 'mt-auto rotate-180 duration-100' : 'rotate-0 duration-100'
      "
      class="m-1 h-5 w-5 cursor-pointer"
      aria-hidden="true"
      @click="open = !open"
    />
    <div
      v-if="open"
      class="flex flex-grow items-center justify-between py-2 pr-2"
    >
      <div class="flex items-center gap-2">
        <div class="" v-html="props.message"></div>
      </div>

      <router-link
        :to="{ name: 'Errors' }"
        class="w-1/6 justify-self-end text-right underline"
        >More info</router-link
      >
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";

const props = defineProps({
  message: {
    type: String,
  },
});

const open = ref(true);
</script>

<style scoped></style>
