import { bcEnvironment } from "@/../version.json";

export const msalConfig = {
  auth: {
    clientId: "b0f818c9-bd3c-47f3-96d6-7396868b5505",
    authority:
      "https://login.microsoftonline.com/c6a0b9e7-e42c-416a-885f-137b72121593",
    // knownAuthorities: [
    //   "https://api.businesscentral.dynamics.com",
    //   "api://b0f818c9-bd3c-47f3-96d6-7396868b5505/user_impersonation",
    // ],
    // redirectUri: location.origin,
    //redirectUri: "timesheet://auth",
    redirectUri:
      process.env.NODE_ENV === "production"
        ? `https://${window.location.host}`
        : "http://localhost:5173",
    // postLogoutRedirectUri: `${location.origin}/#/logout`,
    // postLogoutRedirectUri: `https://timesheet.butspace.com/#/logout`,
    postLogoutRedirectUri:
      process.env.NODE_ENV === "production"
        ? `https://${window.location.host}/logout`
        : "http://localhost:5173/logout",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  endPoints: {
    dynamicsApi:
      // "https://api.businesscentral.dynamics.com/v2.0/LIVE/api/ifacto/timesheet/v1.0/",
      // "https://api.businesscentral.dynamics.com/v2.0/QA/api/ifacto/timesheet/v1.0/",
      `https://api.businesscentral.dynamics.com/v2.0/${bcEnvironment}/api/ifacto/timesheet/v1.0/`,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const dynamicsRequest = {
  scopes: ["https://api.businesscentral.dynamics.com/.default"], //user_impersonation, Financials.ReadWrite.All
};
export const graphRequest = {
  scopes: ["User.Read"], //user_impersonation, Financials.ReadWrite.All
};
