import { defineStore } from "pinia";
import { notify } from "@kyvg/vue3-notification";
import { useBcPersistStore } from "@/stores/bcPersist.js";
import { useUserPersistStore } from "@/stores/userPersist.js";
import WebSocketHelper from "@/utils/WebSocketHelper.js";
import { secondsToFormattedTime } from "@/utils/Utils.js";

export const useIdleStore = defineStore("idle", {
  state: () => {
    return {
      notificationPermission: null,
      idlePermission: null,
      showOverlay: false,
      controller: null,
      signal: null,
      idleThreshold: 5 * 60 * 1000,
      notificationActive: false,
      notificationInterval: null,
      lastNotification: 0,
      // idleThreshold: 60 * 1000,
      prevIdleState: { userState: "", screenState: "" },
      userIdle: false,
      currentTime: 0,
      idleStart: 0,
      selectedIdleTask: null,
      idleTimeSaving: false,
      selectedRows: [],
      timeInterval: null,
      hearthBeatInterval: null,
      swRegistration: null,
    };
  },
  getters: {
    tasksWithDisplayValue: () => {
      const bcPersistStore = useBcPersistStore();
      return bcPersistStore.myTasks
        .filter((t) => t.status !== "Rejected")
        .map((task) => {
          return {
            ...task,
            displayValue: `${task.billToName} | ${task.jobDescription} | ${
              bcPersistStore.items.find(
                (activity) => activity.no === task.itemNo
              )?.description || "No activity selected"
            }`,
          };
        });
    },

    idleTime: (state) => {
      if (state.idleStart !== 0) {
        state.currentTime = Date.now();
        // if (state.idleStart < useUserPersistStore().userSettings.lastActivity) {
        //   state.idleStart = useUserPersistStore().userSettings.lastActivity;
        // }
        return Math.floor((state.currentTime - state.idleStart) / 1000);
      } else {
        return 0;
      }
    },
  },
  actions: {
    async initMessages() {
      const broadcastChannel = new BroadcastChannel("ts_idle_channel");

      broadcastChannel.onmessage = (event) => {
        console.log(event.data.payload);
        switch (event.data.payload) {
          case "keep-action":
            this.keepIdleTime();
            break;
          case "remove-action":
            this.removeIdleTime();
            break;
          default:
            // console.log(`Unknown service worker action: ${event.data.payload}`);
            break;
        }
      };
      await this.closeNotification();
    },

    async closeNotification() {
      if (
        useUserPersistStore().idleNotifications &&
        "Notification" in window &&
        Notification.permission === "granted"
      ) {
        await navigator.serviceWorker
          .getRegistration()
          .then(async (reg) => {
            if (!reg) {
              console.log("No service worker registered!");
              return;
            }
            await reg
              .getNotifications({ tag: "idle" })
              .then((notifications) => {
                notifications.forEach((notification) => {
                  notification.close();
                });
              });
            this.notificationActive = false;
            clearInterval(this.notificationInterval);
            this.notificationInterval = null;
          })
          .catch((e) => console.log(`Close notification ${e}`));
      }
    },

    async displayNotification() {
      const notificationTitle = "B·U·T HR";
      if (
        useUserPersistStore().idleNotifications &&
        "Notification" in window &&
        Notification?.permission === "granted"
      ) {
        await navigator.serviceWorker.getRegistration().then(async (reg) => {
          if (!reg) {
            console.log("No service worker registered!");
            return;
          }

          const options = {
            tag: "idle",
            body: `You were away for ${secondsToFormattedTime(
              this.idleTime,
              true
            )}`,
            icon: "android-chrome-256x256.png",
            vibrate: [100, 50, 100],
            data: {
              dateOfArrival: Date.now(),
              primaryKey: 0,
            },
            requireInteraction: true,
            actions: [
              {
                action: "keep-action",
                title: "Keep",
              },
              {
                action: "remove-action",
                title: "Remove",
              },
            ],
          };

          if (!this.notificationInterval) {
            this.notificationInterval = setInterval(() => {
              reg.getNotifications({ tag: "idle" }).then((notifications) => {
                notifications.forEach((notification) => {
                  notification.close();
                });
                if (this.notificationActive) {
                  options.body = `You were away for ${secondsToFormattedTime(
                    this.idleTime,
                    true
                  )}`;
                  reg.showNotification(notificationTitle, options);
                  this.lastNotification = Date.now();
                } else {
                  clearInterval(this.notificationInterval);
                  this.notificationInterval = null;
                }
              });
            }, 60000);
          }
          if (Date.now() - this.lastNotification < 60000) return;
          await this.closeNotification();
          this.notificationActive = true;
          reg.showNotification(notificationTitle, options);
          this.lastNotification = Date.now();
        });
      }
    },

    async askPermissions() {
      if (!this.checkIdleDetectionSupport()) {
        this.idlePermission = "unsupported";
        return false;
      }
      let overlayTimeout = setTimeout(() => {
        this.showOverlay = true;
      }, 500);
      this.idlePermission = await IdleDetector.requestPermission();
      console.log(this.idlePermission);

      if (this.idlePermission !== "granted") {
        // clearTimeout(overlayTimeout);
        // this.showOverlay = false;
        notify({
          title: "Idle detection permission required.",
          text: "Enable 'Your device use' by clicking the info icon next to the URL.",
          duration: 10000,
        });
        // return console.log("Idle detection permission not granted.");
      }

      //Notification api
      if ("Notification" in window) {
        this.notificationPermission = await navigator.permissions.query({
          name: "notifications",
        });
        if (this.notificationPermission.state === "prompt") {
          console.log("Prompting for notification permission.");
          this.notificationPermission =
            await Notification?.requestPermission().then(() => {
              console.log("hshjfbsdjhfbsjhfb");
            });
        }
      }
      clearTimeout(overlayTimeout);
      this.showOverlay = false;

      let displayMode = "browser";
      const mqStandAlone = "(display-mode: standalone)";
      if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
        displayMode = "standalone";
      }
      if ("Notification" in window && Notification?.permission !== "granted") {
        notify({
          title: "Notification permission required!",
          text:
            displayMode === "browser"
              ? "Enable 'Notifications' by clicking the info icon next to the URL."
              : "Enable 'Notifications' by clicking the 3 dots at the top right and then App Info.",
          duration: 10000,
        });
        return console.log("Notification permission not granted.");
      }
      this.showOverlay = false;
    },

    checkIdleDetectionSupport() {
      if (!("IdleDetector" in window)) {
        // Idle Detector API supported
        notify({
          title: "Idle detection not supported in this browser.",
          text: "This feature is used to help you track the time when you were away from your PC.<br>To use this feature use Chrome.<br>To hide this warning disable idle detection in the top right menu.",
          duration: 30000,
        });
        console.log(
          "Idle detection is not supported on the browser (< chromium 94)"
        );
        useBcPersistStore().enableIdleDetection = false;
        return false;
      } else return true;
    },

    async startIdleDetectionInitially() {
      if (useUserPersistStore().enableIdleDetection) {
        this.startHearthBeat();
        if (useBcPersistStore().activeTask) {
          if (this.checkIdleDetectionSupport()) {
            await navigator.permissions
              .query({ name: "idle-detection" })
              .then((result) => {
                if (result.state === "granted") {
                  this.startIdleDetector();
                } else {
                  // notify({
                  //   title: "Idle detection permission required.",
                  //   text: "Enable 'Your device use' in the browser.",
                  //   duration: 10000,
                  // });
                }
              });
          }
        }
      }
    },

    startHearthBeat() {
      const userPersistStore = useUserPersistStore();
      const bcPersistStore = useBcPersistStore();
      if (
        useBcPersistStore().activeTask &&
        Date.now() - userPersistStore.userSettings.lastActivity >
          this.idleThreshold &&
        userPersistStore.userSettings.lastActivity > 0
      ) {
        this.idleStart = userPersistStore.userSettings.lastActivity;
        this.currentTime = Date.now();
        this.userIdle = true;
        this.timeInterval = setInterval(() => {
          this.currentTime = Date.now();
        }, 1000);
      }

      if (!this.userIdle) {
        userPersistStore.userSettings.lastActivity = Date.now();
        bcPersistStore.putResourceSettings({
          lastActivity: userPersistStore.userSettings.lastActivity,
        });
      }
      if (this.hearthBeatInterval === null) {
        this.hearthBeatInterval = setInterval(() => {
          if (
            !this.userIdle &&
            userPersistStore.userSettings.lastActivity + 59000 < Date.now() &&
            !this.resourceChangeBuffer?.lastActivity
          ) {
            userPersistStore.userSettings.lastActivity = Date.now();
            new WebSocketHelper().wsSend({
              command: "updateHearthBeat",
              lastActivity: userPersistStore.userSettings.lastActivity,
            });
            bcPersistStore.putResourceSettingsWithoutDebounce(
              {
                lastActivity: userPersistStore.userSettings.lastActivity,
              },
              false
            );
          }
        }, 60000);
      }
    },

    async startIdleDetector() {
      this.setBadge();
      try {
        if (this.controller === null || this.signal.aborted) {
          this.controller = new AbortController();
          this.signal = this.controller.signal;
        }
        const idleDetector = new IdleDetector();
        idleDetector.addEventListener("change", () => {
          const userState = idleDetector.userState;
          const screenState = idleDetector.screenState;
          // console.log(
          //   `Idle change: ${userState}, ${screenState}, ${new Date()}`
          // );

          if (
            userState === "active" &&
            screenState === "unlocked" &&
            this.prevIdleState.userState &&
            this.prevIdleState.screenState &&
            !(
              this.prevIdleState.userState === userState &&
              this.prevIdleState.screenState === screenState
            )
          ) {
            const userPersist = useUserPersistStore();
            const storedHearthBeat = JSON.parse(
              JSON.stringify(userPersist.userSettings.lastActivity)
            );
            useBcPersistStore()
              .getResourceSettings()
              .catch(() => {
                // location.reload();
              })
              .finally(() => {
                if (
                  storedHearthBeat !== userPersist.userSettings.lastActivity
                ) {
                  // location.reload();
                }
              });
          }

          if (
            !(userState !== "idle" && screenState !== "locked") &&
            this.idleStart === 0 &&
            !(
              this.prevIdleState.userState === userState &&
              this.prevIdleState.screenState === screenState
            )
          ) {
            if (userState === "idle") {
              this.idleStart = Date.now() - this.idleThreshold;
            } else {
              this.idleStart = Date.now();
            }
            this.timeInterval = setInterval(() => {
              this.currentTime = Date.now();
            }, 1000);
            this.userIdle = true;
          }
          if (
            this.userIdle &&
            !(
              this.prevIdleState.userState !== userState &&
              this.prevIdleState.screenState !== screenState
            )
          ) {
            this.displayNotification();
            this.swRegistration?.update();
          }
          this.prevIdleState.userState = userState;
          this.prevIdleState.screenState = screenState;
        });

        await idleDetector.start({
          threshold: this.idleThreshold,
          signal: this.signal,
        });
        console.log("IdleDetector is active.");
      } catch (err) {
        // Deal with initialization errors like permission denied,
        // running outside of top-level frame, etc.
        console.error(err.name, err.message);
      }
    },

    stopIdleDetector() {
      this.clearBadge();
      if (this.controller) {
        this.controller.abort();
        console.log("IdleDetector is stopped.");
      }
    },

    setBadge() {
      if ("setAppBadge" in navigator && "clearAppBadge" in navigator) {
        //use the native badge API
        navigator.setAppBadge().catch((error) => {
          console.log(error);
        });
      }
    },

    clearBadge() {
      if ("setAppBadge" in navigator && "clearAppBadge" in navigator) {
        //use the native badge API
        navigator.clearAppBadge().catch((error) => {
          console.log(error);
        });
      }
    },

    keepIdleTime() {
      const bcPersistStore = useBcPersistStore();
      const userPersist = useUserPersistStore();
      this.idleTimeSaving = true;
      if (this.selectedIdleTask === "NEW_TASK") {
        userPersist.userSettings.storedIdleTime += this.idleTime / 3600;
        bcPersistStore
          .putResourceSettings({
            storedIdleTime: userPersist.userSettings.storedIdleTime,
          })
          .then(() => {
            new WebSocketHelper().wsSend({
              command: "updateStoredIdleTime",
              idleTime: userPersist.userSettings.storedIdleTime,
            });
          });
        this.removeIdleTime();
      } else if (this.selectedIdleTask) {
        const selectTask = bcPersistStore.myTasks.find(
          (x) => x.id === this.selectedIdleTask
        );
        selectTask.quantity = this.idleTime / 3600 + selectTask.quantity;
        bcPersistStore
          .updateDynamicsData({
            companyGuid: selectTask.companyGuid,
            urlSegment: "timeRegistrationEntries",
            id: selectTask.id,
            stateName: "myTasks",
            fieldNames: ["quantity"],
            fieldValues: [selectTask.quantity],
          })
          .then(() => {
            new WebSocketHelper().wsSend({
              command: "updateTask",
              id: selectTask.id,
              companyGuid: selectTask.companyGuid,
            });
          })
          .catch((e) => {
            //todo: how to inform the user?
          })
          .finally(() => {
            this.removeIdleTime();
          });
        // await removeIdleTime();
      } else {
        this.closeNotification();
        clearTimeout(this.timeInterval);
        this.userIdle = false;
        this.idleStart = 0;
        this.idleTimeSaving = false;

        new WebSocketHelper().wsSend({
          command: "keepIdleTime",
        });
        userPersist.userSettings.lastActivity = Date.now();
        bcPersistStore.putResourceSettings({
          lastActivity: userPersist.userSettings.lastActivity,
        });
      }
    },

    removeIdleTime() {
      const bcPersistStore = useBcPersistStore();
      const userPersist = useUserPersistStore();
      if (!bcPersistStore.activeTask) {
        useUserPersistStore().pushError({
          message: `Unable to remove idle time. No active task found.`,
        });
        return;
      }
      this.idleTimeSaving = true;
      this.closeNotification();
      this.selectedIdleTask = null;
      clearTimeout(this.timeInterval);

      let startTime = new Date(bcPersistStore.activeTask.timeSheetBeginTime);
      let workedTime = Math.round(
        (new Date().getTime() - startTime.getTime()) / 1000 - this.idleTime
      );

      //Hardcode to prevent non admins from having negative time
      if (
        workedTime < 0 &&
        userPersist.linkedResource.timeSheetUserType.toLowerCase() !== "admin"
      ) {
        workedTime = 0;
      }

      bcPersistStore.activeTask.quantity =
        workedTime / 3600 + bcPersistStore.activeTask.quantity;
      bcPersistStore.activeTask.timeSheetBeginTime = new Date().toISOString();

      bcPersistStore
        .updateDynamicsData({
          companyGuid: bcPersistStore.activeTask.companyGuid,
          urlSegment: "timeRegistrationEntries",
          id: bcPersistStore.activeTask.id,
          stateName: "myTasks",
          fieldNames: ["timeSheetBeginTime", "quantity"],
          fieldValues: [
            bcPersistStore.activeTask.timeSheetBeginTime,
            bcPersistStore.activeTask.quantity,
          ],
        })
        .then(() => {
          new WebSocketHelper().wsSend({
            command: "removeIdleTime",
            id: bcPersistStore.activeTask.id,
            companyGuid: bcPersistStore.activeTask.companyGuid,
          });
          userPersist.userSettings.lastActivity = Date.now();
          bcPersistStore.putResourceSettings({
            lastActivity: userPersist.userSettings.lastActivity,
          });
        })
        .catch((e) => {
          useUserPersistStore().pushError(e);
          this.userIdle = false;
          this.idleStart = 0;
        })
        .finally(() => {
          this.userIdle = false;
          this.idleTimeSaving = false;
          this.idleStart = 0;
        });
    },
  },
});
